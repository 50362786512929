<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back()"></i>
          </router-link>
        </li>
        <li>{{t('extra.virtualCurrency')}}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="Pay_usdt">
        <a-radio-group
          v-model:value="currentIndex"
          id="coinPayChannelRadioGroup"
        >
          <a-radio
            :value="index"
            v-for="(item, index) in coinPayChannel"
            :key="index + ',' + item.id"
          >
            <div class="radioItem">
              <div class="item1">
                {{ item.coinType }} 
              </div>
              <div class="item2">
                {{ item.coinChannel }}
                <span v-if="item.giftRatio > 0" class="gift">{{ t("common.rechargeWay.Gift") }}</span>
              </div>
            </div>
          </a-radio>
        </a-radio-group>
      </div>
      <div class="Sys_submit_btn recharge">
        <button @click="goDetail">
          {{ t("common.button.confirm") }}
        </button>
      </div>
    </div>
    <Loading :loading="loading" />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "@/lang";
import api from "@/api";
import Loading from "@/components/Loading.vue";
import {currentCoinPayChannel} from "@/hooks/currentCoinPayChannel";
export default defineComponent({
  components: { Loading },
  setup() {
    onMounted(async () => {
      try {
        loading.value = true;
        let res = await api.getPayChannelList();
        loading.value = false;
        let list = res?.data?.data?.coinPayChannelVoList;
        if (list) {
          coinPayChannel.value = list;
          currentIndex.value = 0;
        }
      } finally {
        loading.value = false;
      }
    });
    const formModel = reactive({
      isShowGift:false
    });
    const loading = ref(false);
    const router = useRouter();
    const coinPayChannel = ref(null);
    const currentIndex = ref(null);
    const { t } = useI18n();
    const goDetail = () => {
      if (currentIndex.value == null) {
        return;
      } else {
        currentCoinPayChannel.value = coinPayChannel?.value?.[currentIndex.value];
        router.push({ path: "/coinPayDetail" });
      }
    };
    return {
      formModel,
      t,
      coinPayChannel,
      currentIndex,
      loading,
      goDetail,
    };
  },
});
</script>
<style lang="scss" scoped>
.Pay_usdt {
  margin-bottom: 30px;
  width: 345px;
  margin-left: 15px;
}
</style>
<style lang="scss">
#coinPayChannelRadioGroup {
  box-sizing: border-box;
  padding: 0px 5px;
  margin-top: 10px;
  width: 100%;
  .ant-radio-wrapper {
    box-sizing: border-box;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    background: #fff;
    border-radius: 10px;
    padding-left: 20px;
  }
  .radioItem {
    display: flex;
    color: #333;
    flex-wrap: nowrap;
    .item1 {
      font-size: 16px;
      font-weight: bold;
    }
    .item2 {
      font-size: 14px;
      margin-left: 10px;
      line-height: 28px;
    }
  }
}
</style>
