import {
    ref
} from 'vue'
export const currentCoinPayChannel = ref(null)
// address: "sdxcxcffffsssss121"
// coinChannel: "ERC-20"
// coinName: "USDT充值"
// coinRate: 200
// coinType: "USDT"
// icon: "common/1950182621.png"
// id: 1
// maxMoney: 10000000
// minMoney: 100